/* eslint-disable no-unused-vars */

//
export const projectsData = [
  {
    id: 0,
    projectName: "OOt Social",
    projectDesc:
      "Human Connectivity Platform. I was responsible for developing, deploying and maintaining OOt Social's mobile application as well as backend systems.",
    tags: ["Android", "iOS", "Serverside"],
    demo: "https://apps.apple.com/ca/app/oot-social-health/id1634972778",
    code: "https://oot.live/",
    image:
      "https://i0.wp.com/oot.live/wp-content/uploads/2022/04/White-Logo-No-Donut.png?w=2054&ssl=1",
    underDevelopment: false,
  },
  {
    id: 1,
    projectName: "Blend Studio - Generative Product Photos",
    projectDesc: "AI image background remover and generator.",
    tags: ["Android", "iOS", "Serverside"],
    demo: "https://play.google.com/store/apps/details?id=to.blend.mobile_app",
    code: "https://www.blendnow.com/web",
    image: "https://www.blendnow.com/static/blend-logo.svg",
    underDevelopment: false,
  },

  {
    id: 3,
    projectName: "Framez App - Photography Community",
    projectDesc:
      "Photography community facilitating photo competitions and developing a Framez score. I have worked on developing both Android and iOS applications.",
    tags: ["Android", "iOS", "Photography"],
    demo: "https://play.google.com/store/apps/details?id=com.framez.framez",
    code: "https://www.framez.in/",
    image:
      "https://play-lh.googleusercontent.com/fIcXNSisdnNXeTcfHlJAZ08-LOVWloNzz-59BFL5NuZ3vWfft0DDXMD5kBB3WqaxXe8=w832-h470-rw",
    underDevelopment: false,
  },
  {
    id: 2,
    projectName: "OMDemand",
    projectDesc:
      "Online podcast application allowing users to listen, download, and share audio content.",
    tags: ["Android", "iOS", "Wordpress"],
    code: "https://www.omdemand.com.ar/",
    demo: "https://apps.apple.com/ca/app/omdemand/id1462278680",
    image: "https://www.omdemand.com.ar/wp-content/uploads/2017/10/logo3-1.png",
    underDevelopment: false,
  },
  {
    id: 4,
    projectName: "Our Easy Game - Educational Content Platform",
    projectDesc:
      "Online educational platform assisting students with assignments and study materials.",
    tags: ["Fullstack"],
    code: "https://oureasygame.com/",
    demo: "https://play.google.com/store/apps/details?id=our.easy.game",
    image:
      "https://oureasygame.com/wp-content/uploads/2023/05/cropped-WhatsApp-Image-2023-01-28-at-16.43.35-removebg-preview-removebg-preview.png",
    underDevelopment: false,
  },
  {
    id: 5,
    projectName: "Mantra FM",
    projectDesc: "Online FM application officially for Mantra FM 91.9.",
    tags: ["Android", "iOS"],
    demo: "https://apps.apple.com/es/app/mantra-fm/id1171847717",
    code: "https://mantrafm.com.ar/",
    image:
      "https://www.mantrafm.com.ar/site2020/wp-content/uploads/2020/04/logo.png",
    underDevelopment: false,
  },
  {
    id: 5,
    projectName: "Enabimnio",
    projectDesc:
      "Master authentic connection through self expression, and become the person you have always been, but were too afraid to be.",
    tags: ["Android", "iOS"],
    code: "https://www.enabimnio.com/",
    demo: "https://apps.apple.com/in/app/enabimnio/id1499767587",
    image: "https://enabimnio.com/assets/assets/images/phone_image.png",
    underDevelopment: false,
  },
  {
    id: 6,
    projectName: "Audioghar",
    projectDesc: "Online audiobook platform with subscription-based access.",
    tags: ["Android", "iOS", "Firebase", "Serverside"],
    code: "https://audioghar.com/",
    demo: "https://play.google.com/store/apps/details?id=com.audioghar.app&hl=en",
    image: "https://audioghar.com/assets/img/gallery/gallery-4.jpg",
    underDevelopment: false,
  },
  {
    id: 7,
    projectName: "Smart Gymrat",
    projectDesc:
      "A simple, minimalist app to keep track of your workout and diet.",
    tags: ["reactjs", "supabase", "nodejs"],
    demo: "https://gym.utsavghimire.com.np",
    code: "https://gym.utsavghimire.com.np",
    image:
      "https://thumbs.dreamstime.com/b/man-weight-training-equipment-sport-gym-22843139.jpg",
    underDevelopment: false,
  },
];
